import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {Device} from "@ionic-native/device/ngx";
import { IonicStorageModule } from '@ionic/storage-angular';
import {ServiceApiService} from './service-api.service';
import {HttpClientModule} from '@angular/common/http';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from "@ionic-native/file-opener/ngx";
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {Clipboard} from "@ionic-native/clipboard/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {AppVersion} from "@ionic-native/app-version/ngx";


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    IonicStorageModule.forRoot(),HttpClientModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Clipboard,NavParams,AppVersion,InAppBrowser,SocialSharing,AndroidPermissions,FileTransfer,File,FileOpener,PhotoViewer,ServiceApiService,Device],
  bootstrap: [AppComponent],
})
export class AppModule {}
