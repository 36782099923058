import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [

  {
    path: '',
    redirectTo: 'appointments',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },

  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },

  {
    path: 'requirement',
    loadChildren: () => import('./requirement/requirement.module').then( m => m.RequirementPageModule)
  },
  {
    path: 'requirement-details',
    loadChildren: () => import('./requirement-details/requirement-details.module').then( m => m.RequirementDetailsPageModule)
  },
  {
    path: 'interview',
    loadChildren: () => import('./interview/interview.module').then( m => m.InterviewPageModule)
  },
  {
    path: 'applicant',
    loadChildren: () => import('./applicant/applicant.module').then( m => m.ApplicantPageModule)
  },
  {
    path: 'applicant-details',
    loadChildren: () => import('./applicant-details/applicant-details.module').then( m => m.ApplicantDetailsPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },
  {
    path: 'tab-requirements',
    loadChildren: () => import('./tab-requirements/tab-requirements.module').then( m => m.TabRequirementsPageModule)
  },
  {
    path: 'interview-booked',
    loadChildren: () => import('./interview-booked/interview-booked.module').then( m => m.InterviewBookedPageModule)
  },
  {
    path: 'pre-interview-details',
    loadChildren: () => import('./pre-interview-details/pre-interview-details.module').then( m => m.PreInterviewDetailsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
