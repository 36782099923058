import {Component} from '@angular/core';
import {AlertController, MenuController, NavController, Platform} from '@ionic/angular';
import {Plugins} from '@capacitor/core';
import {ServiceApiService} from './service-api.service';
import {Location} from '@angular/common';
import {Storage} from '@ionic/storage';
import {Device} from "@ionic-native/device/ngx";
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import {AppVersion} from '@ionic-native/app-version/ngx';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  PlatformName: any;
  appversioncode:any;
  DeviceCordova: any;
  DeviceModel: any;
  DevicePlatform: any;
  DeviceUUID: any;
  DeviceVersion: any;
  DeviceManufacturer: any;
  DeviceSerial: any;

  constructor(public platform: Platform,
              public storage: Storage,
              public service: ServiceApiService,
              public alertCtrl: AlertController,
              public navCtrl: NavController,
              public iab:InAppBrowser,
              public appVersion:AppVersion,
              public device: Device,
              public menuCtrl: MenuController,
              public location: Location) {
    this.platform.ready().then(() => {
      // // Hide the splash (you should do this on app launch)
      // SplashScreen.hide();
      console.log('Device UUID is: ' + this.device.uuid);
      this.DeviceCordova = this.device.cordova;
      this.DeviceModel = this.device.model;
      this.DevicePlatform = this.device.platform;
      this.DeviceUUID = this.device.uuid;
      this.DeviceVersion = this.device.version;
      this.DeviceManufacturer = this.device.manufacturer;
      this.DeviceSerial = this.device.serial;

      this.firebaseNotification();
      this.check_app_up_to_date();
      this.service.baseUrl = 'https://wwgsadmin.awtech.top/';
      this.service.WWGS_Admin_baseUrl = 'https://wwgsadmin.awtech.top/'; // liveWWGS
      // this.service.baseUrl = 'http://192.168.0.220/wwgs/';
      // this.service.WWGS_Admin_baseUrl = 'http://192.168.0.220/wwgs/';

      Promise.all([this.storage.get('LoginData')]).then(values => {
        if (values[0] !== '' && values[0] !== 'null' && values[0] !== null) {
          this.menuCtrl.enable(true);
          // this.navCtrl.navigateRoot('appointments');
          this.navCtrl.navigateRoot('tabs');
        } else {
           this.logout();
        }
      });

      this.service.pages = [
        // {title: 'Change Password', component: ChangePasswordPage},

         {title: 'Requirements', url: '/tabs', icon: 'home'},
        {title: 'Log Out', url: '/login', icon: 'log-out'}
      ];
    });
  }
  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
  }
  openPage(page) {

    console.log('page.url->', page.url);
    // close the menu when clicking a link from the menu

    // navigate to the new page if it is not the current page
    // this.nav.setRoot(page.component);
    if (page.url === '/login') {
      this.alertCtrl.create({
        header: 'Logout',
        message: 'Do you want to logout ?',
        backdropDismiss: false,
        buttons: [{
          text: 'Stay',
          role: 'cancel',
          handler: () => {
            console.log('Application exit prevented!');
          }
        }, {
          text: 'Logout',
          handler: () => {
            console.log('logout!');
            this.logout();
          }
        }]
      }).then(alert => {
        alert.present();
      });

    } else if (page.url !== '/login') {
      this.navCtrl.navigateRoot(page.url);
      this.menuCtrl.enable(true);

    } else {
      // Since the component is null, this is the logout option
      // ...
      // logout logic
      this.alertCtrl.create({
        header: 'Logout',
        message: 'Do you want to logout ?',
        backdropDismiss: false,
        buttons: [{
          text: 'Stay',
          role: 'cancel',
          handler: () => {
            console.log('Application exit prevented!');
          }
        }, {
          text: 'Logout',
          handler: () => {
            console.log('logout!');
            this.logout();
          }
        }]
      }).then(alert => {
        alert.present();
      });
      // ...
      // redirect to home
      // this.nav.setRoot(HomePage);
    }
  }
  logout() {
    this.menuCtrl.enable(false);
    //this.storage.set('UserData', '');
    this.storage.set('LoginData', '');
    this.navCtrl.navigateRoot('/login');
  }
  check_app_up_to_date(){

    console.log('check_app_up_to_date');

    if (this.platform.is('android') || this.device.platform == 'Android') {
      this.PlatformName = 'ANDROID';
    }
    if (this.platform.is('ios') || this.device.platform == 'iOS') {
      this.PlatformName = 'iOS';
    }

    this.appVersion.getVersionNumber().then(getVersionNumber => {
      console.log("getVersionNumber "+getVersionNumber);
      this.appversioncode=getVersionNumber;
      this.appVersion.getPackageName().then(getPackageName => {
        console.log("getPackageName "+getPackageName);
        this.service.getPackageName=getPackageName;
        this.service.WWGS_Admin_httpcall('web_service/get_app_details?params[PlatformName]=' + this.PlatformName+'&params[PackageName]=' + getPackageName).subscribe(async (app_details: any[]) => {
          console.log(app_details);
          console.log(getVersionNumber +" "+ app_details[0]['VersionNumber']);
          var VersionNumber = getVersionNumber.split('.').join("");
          var UpdatedVersionNumber = app_details[0]['VersionNumber'].split('.').join("");
          if(Number(VersionNumber) < Number(UpdatedVersionNumber)){
            const update_alert =await this.alertCtrl.create({
              header: 'Update Available',
              message: 'Download the latest version',
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    console.log('Updated successfully');
                    //  window.location.href = app_details[0]['AppLink'];
                    const browser = this.iab.create(app_details[0]['AppLink'],'_system',{location:'no'});
                  }
                }
              ],
              backdropDismiss: false
            });

            await update_alert.present();

          }else{
            console.log("Version Up To Date");
          }

        });
      }).catch((e)=>{
        console.log(e);
      });
    })


  }
  firebaseNotification() {
    console.log('Initializing HomePage');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
          this.service.token = token.value;
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      //  alert('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
          // this.localNotifications.schedule([{
          //     id: this.localNotificationsId+1,
          //     title: notification.title,
          //     text: notification.body,
          //     icon: '../../assets/icon/icon.png'
          // }]);

        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
      console.log('notification received',notification);
          //alert('Push action performed: ' + JSON.stringify(notification));
        }
    );
  }
}
