import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AlertController, ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ServiceApiService {
  baseUrl: string;
  token: any;
  UserName: any;
  getPackageName:any;
  appversioncode: any;
  pages: Array<{ title: string, url: any, icon: any }>;
  WWGS_Admin_baseUrl:string;
  FRequirementId:any;



  constructor(public httpClient: HttpClient,
              public toastCtrl: ToastController,
              public alertCtrl: AlertController) {
  }

  // Http Call Universal
  public httpcall(url) {
    console.log('this is url' + url);
    return this.httpClient.get(this.baseUrl + url + '&randomvalue=' + Math.random())
        .pipe(map(data => {
          return data;
        }));
  }

  public httppostcall(url, data) {
    console.log(url + JSON.stringify(data));
    return this.httpClient.post(this.baseUrl + url, data)
        .pipe(map(res => {
          return res;
        }));
  }
  public WWGS_Admin_httpcall(url) {
    console.log('this is url' + url);
    // return this.http.get(this.WWGS_Admin_baseUrl + url + '&randomvalue=' + Math.random())
    //     .pipe(map(data => {
    //       //console.log(data['_body']);
    //       return data;
    //     }));
    return this.httpClient.get(this.WWGS_Admin_baseUrl + url + '&randomvalue=' + Math.random())
        .pipe(map(data => {
          return data;
        }));
  }
  public WWGS_Admin_httppostcall(url, data) {
    console.log(url + JSON.stringify(data));
    //return this.httpClient.post(this.baseUrl + url, JSON.stringify(data), {headers:{'Content-Type':'application/json'}})
    // return this.http.post(this.WWGS_Admin_baseUrl + url, JSON.stringify(data))
    //     .pipe(map(res => {
    //       //console.log(data['_body']);
    //       return res;
    //     }));
    return this.httpClient.post(this.WWGS_Admin_baseUrl + url, data)
        .pipe(map(res => {
          return res;
        }));
  }

  async toastMethod(txtmessage) {
    const toast = this.toastCtrl.create({
      message: txtmessage,
      duration: 3000,
      position: 'bottom'
    });

    (await toast).present();
  }

  async presentAlert(header,message) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      mode:'ios',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  async alertMenthod(header,Message){
    const alert =await this.alertCtrl.create({
      header: header,
      message: Message,
      buttons: ['OK']
    });
    await alert.present();
  }
  async alertMethod(txt, txtmessage) {
    this.alertCtrl.create({
      header: txt,
      message: txtmessage,
      // backdropDismiss: false,
      buttons: [{
        text: 'Ok',
        role: 'cancel',
        handler: () => {
          console.log('close');
        }
      }]
    })
        .then(alert => {
          alert.present();
        });
  }
  public httpcall_school(url) {
    console.log('this is url' + url);
    return this.httpClient.get( url + '&randomvalue=' + Math.random())
        .pipe(map(data => {
          return data;
        }));
  }
  public httppostcall_school(url, data) {
    console.log(url + JSON.stringify(data));
    //return this.httpClient.post(this.baseUrl + url, JSON.stringify(data), {headers:{'Content-Type':'application/json'}})
    return this.httpClient.post( url, JSON.stringify(data))
        .pipe(map(res => {
          return res;
        }));
  }
  getSchoolUrl(SchoolId)
  {
    if(SchoolId == 1) {
      return 'http://geis.awtech.top/'
      //return 'http://192.168.0.122/Latest_geis.co.in/';

    }
    if(SchoolId == 2)
    {
      return 'http://gurukulwave.com/west/';
      //return 'http://192.168.0.177/gurukulwave/';
    }
    if(SchoolId == 3)
    {
      return 'http://gurukulwave.com/east/';
      //return 'http://192.168.0.177/gurukulwave/';
    }
    if(SchoolId == 4)
    {
      return 'http://gpis.awtech.top/';
      //return 'http://192.168.0.177/gurukulwave/';
    }
  }
  async presentToast(message){
    const toast = await this.toastCtrl.create({
      message:message,
      duration: 2000,
      mode:'ios'
    });
    toast.present();
  }

  async presentTopToast(message){
    const toast = await this.toastCtrl.create({
      message:message,
      duration: 2000,
      position:'top',
      mode:'ios'
    });
    toast.present();
  }

  async presentMiddleToast(message){
    const toast = await this.toastCtrl.create({
      message:message,
      cssClass: 'backtoast',
      duration: 2000,
      position:'middle',
      mode:'ios'
    });
    toast.present();
  }
}
